import React, {Component} from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import CapabilityTab from "../components/capabilityTab"

class CapabilityPage extends React.Component {

    constructor(props) {
      super(props);
      this.state ={
        hero: "https://images.prismic.io/levelfivesolutions/f07819f5-7b96-4e01-b14d-a78e0b529339_banner_capabilties_overview.jpg?auto=compress%2Cformat&fit=max&q=50"
      }
      this.setUrlWithUid = this.setUrlWithUid.bind(this);
      this.handleTabChange = this.handleTabChange.bind(this);
    }

    //returns the active tab uid from the URL
    getCategoryUidFromUrl() {
      return (window.location.search != "") ? window.location.search.match(/(=)\w+/)[0].replace("=","") : "";
    }

    //Sets the url parameters for the active tab
    setUrlWithUid(uid) {
      var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + '?tab=' + uid;    
      window.history.pushState({ path: refresh }, '', refresh);
    }

    //Clicks a tab to make it active
    setActiveTab(uid) {
      if(uid) {
        let tabs = document.getElementsByClassName("tab_anchor");
        console.log("TABS", tabs);
        for (let tab of tabs) {
          if(tab.hash === `#${uid}`) {
            tab.click();
          }
        }
      }
    }

    handleTabChange(category) {
      console.log("Handle tab change", category);
      this.setState({
        hero: category.data.wide_image.url
      })
      this.setUrlWithUid(category.uid);
      console.log("Get Category from url", this.getCategoryUidFromUrl())
    }

    componentDidMount() {
      this.setActiveTab(this.getCategoryUidFromUrl());
    }

    render() {
      const { data } = this.props;
      
      console.log('>>>', data);
      let unmodifiedCategories = data.categories.nodes[0].data.capability_categories;
      console.log('categories -->', unmodifiedCategories);

      let categories = unmodifiedCategories.map( (category) => {
        let retCategory = [];
        retCategory.data = category.capabilities.document.data;
        retCategory.uid = category.capabilities.uid;
        return retCategory;
      })

      const capabilities = data.categories.nodes[0].data;
    
      if (!categories) return null;

      return (
          <Layout>
          <SEO title={ capabilities.meta_title } image={ capabilities.meta_og_image.url } description={ capabilities.meta_description } canonical={ typeof window !== 'undefined' && (window.location.origin + window.location.pathname)}/>

              {/* preload images */}
              <div style={{ display: "none"}}>

              {
                categories.map( (category, i) => {
                  return(
                    category.data.wide_image && <img key={ i } src={ category.data.wide_image.url } alt={ category.data.wide_image.alt }/>
                    )
                  })
                }
                </div>

              <div id="title-bar" className="title-bar banner-overview" alt="capabilities" style={{ backgroundImage: `url(${this.state.hero})`}}>
                <div className="container">
                    <div className="wrapper">
                        <h1 className="page-title">Capabilities</h1>
                        <div className="clearfix"></div>
                        <div className="nav-tabs-wrapper">
                            <ul role="tablist" className="nav nav-tabs">
                              {
                                categories.map( (category, j) => {
                                  return(
                                    <li role="presentation" key={ j } className={ (j === 0) ? "active" : "" }>
                                      <a onClick={ () => this.handleTabChange(category) } href={`#${ category.uid} `} className="tab_anchor" aria-controls="" role="tab" data-toggle="tab">{ category.uid.replace('-',' ')}</a>
                                    </li>
                                  );
                                })
                              }
                            </ul>
                        </div>
                        <div className="title-bar-accent"></div>
                    </div>
                </div>
            </div>

            {/*  PAGE BODY  */}
            <section id="body-area">

                <div className="container">
                    <div className="tab-content">
                        {
                          categories.map( (category, i) => {
                            return(
                              <div role="tabpanel" key={ i } className={ `tab-pane ${(i === 0) ? "active" : "" }`} id={ category.uid }>
                                <CapabilityTab key={i} data={ category.data } />
                              </div>
                            );
                          })
                        }
                    </div>
                </div>

            </section>


          </Layout>
      );
                    }
}

export default CapabilityPage;

export const capabilityPageQuery = graphql`
query capabilityQuery {
  categories: allPrismicCapabilities {
    nodes {
      data {
        capability_categories {
          capabilities {
            uid
            document {
              ... on PrismicCapabilityCategory {
                data {
                  body {
                    ... on PrismicCapabilityCategoryBodyCapabilityToolbox {
                      id
                      slice_type
                      items {
                        capability_tool
                      }
                      primary {
                        capability_toolbox_description {
                          html
                        }
                        capability_toolbox_label {
                          text
                        }
                      }
                    }
                  }
                  capabilities {
                    capability_description {
                      html
                    }
                    capability_image {
                      alt
                      url
                    }
                    capability_title {
                      text
                    }
                  }
                  capabilities_label
                  capability_category_description {
                    html
                    text
                  }
                  capability_category_tagline
                  capability_category_title {
                    html
                    text
                  }
                  wide_image {
                    alt
                    url
                  }
                }
              }
            }
          }
        }
        meta_description
        meta_og_image {
          url
        }
        meta_title
      }
    }
  }
}

`
